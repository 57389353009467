import React, { useContext, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { PttFieldType } from "../../types";
import Field from "./Field";
import { GlobalContext } from "../../contexts/GlobalContext";
import Button from "../../components/Button";
import fetchAPI from "../../lib/fetchAPI";

interface ResetPasswordProps extends PttFieldType {}

const ResetPassword = ({ field }: ResetPasswordProps): React.ReactElement => {
  const { setShowErrors, getErrors, showErrors, storedData, setErrors } =
    useContext(EditDataContext);
  const { getLiteral, getToken } = useContext(GlobalContext);
  const errors = getErrors({ field });
  const [success, setSuccess] = useState("")
  const [sending, setSending] = useState(false)

  const sendEmail = () => {
    if (sending) return
    setSending(true)
    setShowErrors(false);
    setErrors({});
    if (!storedData?.email) {
      setErrors({ ...errors, resetpassword: [getLiteral("form.errors.required")] });
      setShowErrors(true);
      return;
    }
    sendRecoverEmail();
  }

  const sendRecoverEmail = async () => {
    const response = await fetchAPI({
      url: "session/set-user-password",
      method: "POST",
      body: { email: storedData?.email },
      token: getToken(),
    });

    if (!response.valid) {
      setErrors({ ...errors, resetpassword: [getLiteral("form.errors.required")] });
      setShowErrors(true);
    } else {
      const title = getLiteral("email_sent") as string
      setSuccess(title)
    }
    setSending(false)
  }

  return (
    <Field field={field}>
      <Button
        theme="dark"
        onClick={sendEmail}
      >{!sending ? field.options.cta as string : field.options.ctaLoading as string}</Button>
      {showErrors && Object.keys(errors).length > 0 && (
        <div className="mt-1 text-sm text-error">
          {errors.map((err: string) => (
            <div key={err}>{err}</div>
          ))}
        </div>
      )}
      {success && (
        <p className="mt-1 text-xs text-public">{success}</p>
      )}
    </Field>
  );
};

export default ResetPassword;
