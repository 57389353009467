import React, { useContext, useEffect, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { SelectOptionType, PttModelField } from "../../types";
import Field from "./Field";
import { GlobalContext } from "../../contexts/GlobalContext";
import { Link, useSearchParams } from "react-router-dom";
interface SelectType extends PttModelField {
  options: {
    showLink?: boolean;
    label: string;
    type: "static" | "country";
    search?: boolean;
    multiple?: boolean;
    valueType?: string;
    model?: string;
    options: { [key: string]: string };
  };
}

const Select = ({ field }: { field: SelectType }) => {
  const { updateStored, getValue, getErrors, getLanguage, showErrors, data } =
    useContext(EditDataContext);

  const { getLiteral } = useContext(GlobalContext);

  const language = getLanguage({ field });
  const value = getValue({
    field,
    defaultValue: field?.options?.multiple && field.options.valueType !== 'string' ? [] : undefined,
  });
  const errors = getErrors({ field });

  const [options, setOptions] = useState<SelectOptionType[]>([]);
  const [searchValue, setSearchValue] = useState<string>(field?.options?.multiple ? 'Search' : '')
  const [searchOptions, setSearchOptions] = useState<SelectOptionType[]>([])

  const [searchParams] = useSearchParams();
  const predefinedValue = searchParams.get(field.name)
  useEffect(() => {
    if (predefinedValue) {
      value[language] = predefinedValue
      updateStored(field, value);
    }
  }, [predefinedValue])

  const shouldAddMultipleValue = (val: string[], v: string) => {
    if (!val.includes(v)) {
      val.push(v);
    }
    if (field.options.valueType === 'string') {
      return val.filter((item: string) => item !== '').join(',');
    }
    return [...val];
  };

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (field.options.valueType === 'string') {
      value[language] = field?.options?.multiple
        ? shouldAddMultipleValue(value[language].split(',') as string[], e.target.value)
        : e.target.value;
    } else {
      value[language] = field?.options?.multiple
        ? shouldAddMultipleValue(value[language] as string[], e.target.value)
        : e.target.value;
    }
    updateStored(field, value);
  };

  const removeItem = (id: string) => {
    if (field.options.valueType === 'string') {
      const valueItems = value[language].split(',') as string[];
      value[language] = valueItems.filter((item: string) => item !== id).join(',');
    } else {
      const valueItems = value[language] as string[];
      value[language] = valueItems.filter((item: string) => item !== id);
    }
    updateStored(field, value);
  };

  useEffect(() => {
    if (
      (!field?.options?.type || field?.options?.type === "static") &&
      field?.options?.options
    ) {
      const options = Object.keys(field?.options?.options).map((key: string) => ({
        _id: key,
        title: `${field?.options?.options[key]}`,
      }));
      if (options[0]?._id !== "-1") {
        options.unshift({ _id: "-1", title: `No ${field?.options?.label}` });
      }
      setOptions(options);
    } else {
      setOptions([{ _id: "-1", title: "Loading..." }]);
      if (
        typeof field?.options?.model !== "undefined" &&
        data &&
        typeof data.find !== "undefined" &&
        typeof data.find[field?.options?.model] !== "undefined"
      ) {
        const options = data.find[field?.options?.model];
        if (options[0]?._id !== "-1") {
          options.unshift({ _id: "-1", title: `No ${field?.options?.label}` });
        }
        setOptions([
          ...data.find[field?.options?.model],
        ]);
      }
    }
  }, [field]);

  useEffect(() => {
    if (
      field?.options?.type === "static" &&
      !field.translatable &&
      typeof value?.default === "undefined" &&
      options.length
    ) {
      if (field?.options?.multiple) {
        value[language] = field.options.valueType === 'string' ? '' : [];
      } else {
        value[language] = options[0]._id;
      }
      updateStored(field, value);
    }

    if (!field?.options?.multiple && options.length && value[language]) {
      setSearchValue(options?.find((item) => item._id === value[language])?.title || '')
    }
  }, [field]);

  const multipleOption = (id: string) => {
    let option = options.find((item) => item._id === id);
    return (
      <div
        key={id}
        className="flex-grow-0 flex-shrink-0 p-1 px-2 mb-1 mr-1 text-xs bg-white border border-grayLight"
      >
        {option ? option.title : `Not found ${id}`}
        <span className="pl-1 cursor-pointer" onClick={() => removeItem(id)}>
          ✕
        </span>
      </div>
    );
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    setSearchValue(searchValue)

    if (searchValue !== '') {
      const newOptions = options.filter(option => {
        const optionLowercase = option.title.toLowerCase()
        const searchLowercase = searchValue.toLowerCase()
        return optionLowercase.indexOf(searchLowercase) !== -1
      })
      setSearchOptions(newOptions)
    } else {
      setSearchOptions([])
    }
  }

  const StaticSelect = <div className="relative">
    <div className="absolute right-0 py-1 mr-2">&#8595;</div>
    <select
      name={field.name}
      className={`w-full px-2 py-1 border border-grayLight ${
        showErrors && errors.length > 0 ? "border border-error" : ""
      } outline-none appearance-none`}
      placeholder={field?.options?.label}
      value={
        field?.options?.multiple ? "-1" : (value[language] as string[])
      }
      onChange={onChangeSelect}
    >
      {options.map((option) => (
        <option
          key={option._id + option.title}
          disabled={
            option.disabled ||
            !!(
              field?.options?.multiple &&
              value &&
              value[language] &&
              (value[language] as string[]).includes(option._id)
            )
          }
          value={option._id}
        >
          {option.title}
          {option?.status && ` (${getLiteral(option?.status)})`}
        </option>
      ))}
    </select>
  </div>

  const SearchSelect = <div className="relative">
    <div className="absolute right-0 py-1 mr-2">&#8595;</div>
    <input
      name={field.name}
      className={`w-full px-2 py-1 bg-white outline-none placeholder-gray placeholder-opacity-50 cursor-pointer border ${
        showErrors && errors.length > 0 ? "border-error" : "border-grayLight"
      }`}
      type="text"
      placeholder='Search'
      value={searchValue}
      onFocus={() => {
        setSearchValue('')
        setSearchOptions(options)
      }}
      onBlur={() => {
        setSearchValue(options?.find((item) => item._id === value[language])?.title || '')
        setTimeout(() => {
          setSearchOptions([])
        }, 200);
      }}
      onChange={onChangeSearch}
    />
    <div className="absolute z-20 w-full overflow-y-auto bg-white border-grayLight "
      style={{
        "maxHeight": "136px",
      }}
    >
      {searchOptions && searchOptions?.length > 0
      && searchOptions.map((option) => (
        <option
          key={option._id + option.title}
          className={`w-full px-2 py-1 border outline-none appearance-none cursor-pointer border-grayLight hover:bg-grayLighter ${option._id === value[language] ? 'bg-grayLighter cursor-default' : ''}`}
          value={option._id}
          onClick={() => {
            value[language] = option._id === "-1" ? null : option._id;
            updateStored(field, value);
            setSearchValue(option.title)
            setSearchOptions([])
          }}
        >
          {option.title}
          {option?.status && ` (${getLiteral(option?.status)})`}
        </option>
      ))}
    </div>
  </div>


  return (
    <Field field={field}>
      <div>
        <>
          {field?.options?.search ? SearchSelect : StaticSelect}
          {field?.options?.multiple &&
            value &&
            value[language] &&
            <div className="flex flex-wrap mt-2">
              { field.options.valueType === 'string' ?
                (value[language]?.split(",") as string[])?.length > 0 && (value[language]?.split(",") as string[])?.map(
                  (id) => id.length > 0 && multipleOption(id)
                )
              :
                Array.isArray(value[language]) && (value[language] as string[])?.map(
                  (id) => id.length > 0 && multipleOption(id)
                )
              }
            </div>
          }
        </>
      </div>
      {field?.options?.showLink && value?.default &&
        <div className="absolute top-0 w-full text-xs font-light text-right">
          <Link
            className="cursor-pointer hover:text-primary"
            to={{ pathname: `/admin/edit/${field?.options?.model}/${value.default}` }}
          >{getLiteral("view")}</Link>
        </div>
      }
    </Field>
  );
};

export default Select;
