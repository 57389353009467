import React, { useContext } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { PttFieldType } from "../../types";
import Field from "./Field";
import { GlobalContext } from "../../contexts/GlobalContext";

interface TextProps extends PttFieldType {}

const Text = ({ field }: TextProps): React.ReactElement => {
  const { updateStored, getValue, getErrors, getLanguage, showErrors, storedData, data } =
    useContext(EditDataContext);
  const { getLiteral } = useContext(GlobalContext);

  const language = getLanguage({ field });
  const value = getValue({ field });
  const errors = getErrors({ field });

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    value[language] = e.target.value;
    updateStored(field, value);
  };

  return (
    <Field field={field}>
      <input
        name={field.name}
        className={`w-full px-2 py-1 bg-white outline-none placeholder-gray placeholder-opacity-50 border ${
          showErrors && errors.length > 0 ? "border-error" : "border-grayLight"
        }
        ${field.readonly ? "bg-gray bg-opacity-10 text-gray" : ""}
        `}
        type="text"
        placeholder={
          getLiteral(
            (field?.options as { [key: string]: unknown }).label as string,
            "manager_field_"
          ) || ""
        }
        value={`${value && value[language] ? value[language] : ""}`}
        onChange={onChangeText}
        disabled={field.readonly}
      />
    </Field>
  );
};

export default Text;
