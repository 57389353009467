import { useState } from 'react'
import Modal from './Modal'
import ModalPendingEventFix from './ModalPendingEventFix'
import Button from './Button'
import eventIsReady from '../lib/eventIsReady'

const ModalPendingEvent = ({
  apps,
  installations,
  clients,
  item,
  setItem,
  refresh,
  tablesList,
}: {
  apps: any[]
  installations: any[]
  clients: any[]
  item: any
  setItem: any
  refresh: any
  tablesList: string[]
}) => {
  const [selectedId, setSelectedId] = useState({})
  const OkCell = () => {
    return <td className="p-1 text-green-500 ">OK</td>
  }

  const NotFoundCell = ({ type, id, app }: { type: string; id: string, app?: string }) => {
    return (
      <td className="p-1 cursor-default text-error">
        <span className="mr-2">Not found</span>{' '}
        <Button
          theme="clear"
          onClick={() => !(type === 'installation' && !apps.find((app) => app.appId === item.app)) && setSelectedId({ type, id, app,})}
          disabled={type === 'installation' && !apps.find((app) => app.appId === item.app)}
        >
          Fix
        </Button>
      </td>
    )
  }

  const StatusCell = ({ type }: { type: string }) => {
    if (type === 'installation') {
      if (eventIsReady(installations, item)) {
        return <OkCell />
      } else {
        return <NotFoundCell type="installation" id={item.installation} app={item.app} />
      }
    }

    if (type === 'app') {
      if (apps.find((app) => app.appId === item.app)) {
        return <OkCell />
      } else {
        return <NotFoundCell type="app" id={item.app} />
      }
    }

    return (
      <td>
        <>Not found</>
      </td>
    )
  }

  return (
    <Modal size="w-3/5" setShowModal={() => setItem(null)}>
      <div>
        <h3 className="mb-2 text-xl">{item._id}</h3>
        <p>
          There are <strong>{item.count}</strong> events to process for <strong>{`${item.app}.${item.installation || 'default'}`}</strong>. To
          proceed, you need to fix the following issues:
        </p>
        <table className="w-4/5 mt-4 mb-2">
          <thead className="w-full text-left bg-gray-100">
            <tr className="border-b border-grayLight">
              <th className="p-1">Type</th>
              <th className="p-1">ID</th>
              <th className="p-1">Status</th>
            </tr>
          </thead>
          <tbody className="w-full text-left bg-white">
            <tr className="border-b border-grayLight">
              <td className="p-1">App</td>
              <td className="p-1">{item.app}</td>
              <StatusCell type="app" />
            </tr>
            <tr>
              <td className="p-1">Installation</td>
              <td className="p-1">{item.installation || 'default'}</td>
              <StatusCell type="installation" />
            </tr>
          </tbody>
        </table>
        <div className='flex justify-end w-full'>
          <Button
            theme="danger"
            onClick={() => setItem(null)}
          >
            Close
          </Button>
        </div>
        {Object.keys(selectedId).length > 0 && (
          <ModalPendingEventFix
            setShowModal={() => setSelectedId({})}
            selectedId={selectedId}
            installations={installations}
            clients={clients}
            apps={apps}
            refresh={refresh}
            tablesList={tablesList}
          />
        )}
      </div>
    </Modal>
  )
}

export default ModalPendingEvent
