import React, { useContext } from 'react'
import { EditDataContext } from '../contexts/EditDataContext'
import Button from './Button'
import generateToken from '../lib/generateToken'

interface ModalRulesProps {
  title: string
  name: string
  value: any
  setValue: any
}

const ModalRules = ({ field, extra }: { field: ModalRulesProps, extra?: any }) => {
  const { name, title, value, setValue } = field
  const { data } = useContext(EditDataContext)
  const tables = data?.modelConfig?.tablesList || extra.tables as string[] || []

  const onChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    key: string
  ) => {
    const rawValue = [...value]
    rawValue.map((item) => {
      if (item.key === key) {
        item[event.target.name] = event.target.value
      }
      return item
    })
    setValue(rawValue)
  }

  const onChangeText = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const rawValue = [...value]
    rawValue.map((item) => {
      if (item.key === key) {
        item[event.target.name] = event.target.value
      }
      return item
    })
    setValue(rawValue)
  }

  type ListItemProps = {
    item: any
    key: number
  }

  const ListItem = ({ item }: ListItemProps) => (
    <div
      className="grid w-full grid-cols-12 px-2 py-1 text-xs border-b border-x border-grayLight hover:bg-grayLight hover:bg-opacity-20"
      key={item.key}
    >
      <div className="flex flex-col col-span-2 pr-4">
        <div>
          <select
            name="filter"
            value={item.filter}
            onChange={(event) => onChange(event, item.key)}
            className={`w-full px-2 py-1 border border-grayLight outline-none appearance-none cursor-pointer`}
            style={{ padding: '2px 6px' }}
          >
            <option value="-1">Select</option>
            <option value="table">Table</option>
            <option value="column">Column</option>
            <option value="row">Row</option>
          </select>
        </div>
      </div>
      {item.filter && ['table', 'column', 'row'].includes(item.filter) && (
        <div className="flex flex-col col-span-3 pr-4">
          <div className="flex items-center">
            <select
              name="table"
              value={item.table}
              onChange={(event) => onChange(event, item.key)}
              className={`w-full px-2 py-1 border border-grayLight outline-none appearance-none cursor-pointer`}
              style={{ padding: '2px 6px' }}
            >
              <option value="-1">Select</option>
              {tables &&
                tables.map((table) => <option value={table}>{table}</option>)}
            </select>
          </div>
        </div>
      )}
      {item.filter && ['column', 'row'].includes(item.filter) && (
        <div className="flex flex-col col-span-3 pr-4">
          <input
            key={`${item.key}-column`}
            id={`${item.key}-column`}
            name="column"
            className={`w-full px-2 py-1 border border-grayLight  outline-none appearance-none`}
            style={{ padding: '2px 6px' }}
            type="text"
            placeholder="Column"
            value={item.column}
            onChange={(e) => onChangeText(e, item.key)}
          />
        </div>
      )}
      {item.filter && ['row'].includes(item.filter) && (
        <div className="flex flex-col col-span-3 pr-4">
          <input
            key={`${item.key}-row`}
            id={`${item.key}-row`}
            name="row"
            className={`w-full px-2 py-1 border border-grayLight outline-none appearance-none`}
            style={{ padding: '2px 6px' }}
            type="text"
            placeholder="Row"
            value={item.row}
            onChange={(e) => onChangeText(e, item.key)}
          />
        </div>
      )}
      <span
        className="col-start-12 px-2 m-auto mr-0 cursor-pointer hover:wght-bold"
        onClick={() => removeRule(item.key)}
      >
        ✕
      </span>
    </div>
  )

  const removeRule = (key: string) => {
    const rawValue = [...value]
    setValue(rawValue.filter((item) => item.key !== key))
  }

  const List = ({ items }: { items: any[] }) => {
    const renderItems: JSX.Element[] = []
    items.map((item, index) => {
      return renderItems.push(ListItem({ key: index, item: item }))
    })
    return renderItems
  }

  const addModel = async () => {
    const newValue = value ? [...value] : []
    newValue.push({
      key: generateToken(30),
      type: name === 'includedRules' ? 'included' : 'exclude',
      filter: '-1',
      table: '',
      column: '',
      row: '',
    })
    setValue(newValue)
  }
  return (
    <div className='my-2'>
      <p className='text-xs text-gray'>{title}</p>
      {value?.length > 0 && (
        <div className="mb-2">
          <div className="grid w-full grid-cols-12 px-2 py-1 mt-1 text-xs text-white bg-black border border-black ">
            <p className="col-span-2 pr-4 wght-semibold">Filter by</p>
            <p className="col-span-3 pr-4 wght-semibold">Table</p>
            <p className="col-span-3 pr-4 wght-semibold">Column</p>
            <p className="col-span-3 pr-4 wght-semibold">Row</p>
          </div>
          {List({ items: value })}
        </div>
      )}
      <div className="">
        <Button
          theme="outline-dark"
          onClick={addModel}
        >Add rule</Button>
      </div>
      <div className="flex flex-row items-center justify-end"></div>
    </div>
  )
}

export default ModalRules
