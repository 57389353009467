import React, { useState, useEffect } from 'react'
import { StoredDataType } from '../types'
import { Rule } from './BasicRulesTab'

function BasicRulesIngredientsProportions({
  title,
  data,
  excludedRule,
}: {
  title: string
  data: StoredDataType
  excludedRule: Rule
}) {
  const [isChecked, setIsChecked] = useState<boolean>(false)

  useEffect(() => {
    if (Array.isArray(data.excludedRules)) {
      const ruleExists = data.excludedRules.some(
        (rule: Rule) => rule.key === excludedRule.key
      )
      setIsChecked(ruleExists)
    }
  }, [data, excludedRule])

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked
    setIsChecked(newCheckedState)

    if (newCheckedState) {
      Array.isArray(data.excludedRules) && data.excludedRules.push(excludedRule)
    } else {
      data.excludedRules =
        Array.isArray(data.excludedRules) &&
        data.excludedRules.filter((rule: Rule) => rule.key !== excludedRule.key)
    }
  }

  return (
    <section className='flex flex-col gap-2 p-4 w-full'>
      <header className='border-b w-full mb-4'>
        <h1 className='text-base'>{title}</h1>
      </header>

      <main className='w-full flex flex-col gap-2 items-start'>
        <div className='flex flex-row items-center gap-2'>
          <input
            type='checkbox'
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <p>Hide Proportions</p>
        </div>
      </main>
    </section>
  )
}

export default BasicRulesIngredientsProportions
