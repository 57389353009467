import { ChangeEventHandler, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router'
import Button from '../components/Button'
import Container from '../components/Container'
import Form from '../components/fields/Form'
import { EditDataContext } from '../contexts/EditDataContext'
import { GlobalContext } from '../contexts/GlobalContext'
import fetchAPI from '../lib/fetchAPI'
import { StoredDataType } from '../types'
import ActionsTab from '../components/ActionsTab'
import downloadCSV from '../lib/downloadCsv'
import useLocalStorage from '../hooks/useLocalStorage'
import { addDataApiIdField } from '../lib/dataApiField'
import ModalDuplicateInstallation from '../components/ModalDuplicateInstallation'
import ModalBestiarioApps from '../components/ModalBestiarioApps'
import BasicRulesTab, { Rule } from '../components/BasicRulesTab'
import ReportedEventsTab from '../components/ReportedEventsTab'

type BodyDataType = {
  modelName: string
  _id?: string
}

const Edit = () => {
  const {
    name,
    setName,
    data,
    setData,
    mode,
    setMode,
    ready,
    storedData,
    getValue,
    hasErrors,
    setShowErrors,
    updateStored,
    setStoredData,
  } = useContext(EditDataContext)

  const { getLiteral, getToken, logout, getUser } = useContext(GlobalContext)

  const navigate = useNavigate()
  const { name: modelName, id: urlId } = useParams()

  const [title, setTitle] = useState(name)
  const [saving, setSaving] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [showBestiarioModal, setShowBestiarioModal] = useState(false)
  const [bestiarioCustomerId, setBestiarioCustomerId] = useState('')
  const [bestiarioIncludedRules, setBestiarioIncludedRules] = useState([])
  const [bestiarioExcludedRules, setBestiarioExcludedRules] = useState([])
  const [showDuplicateInstallationModal, setShowDuplicateInstallationModal] =
    useState(false)
  const [groupApps, setGroupApps] = useState([] as string[])
  const [saved, setSaved] = useState(false)
  const [activeTab, setActiveTab] = useState('information')
  const user = getUser()
  const [file, setFile] = useState<File | null>(null)
  const [uploadingEan, setUploadingEan] = useState(false)
  const [importResponse, setImportResponse] = useState<{
    valid: boolean
    message?: string
    error?: string
  } | null>(null)
  const [token] = useLocalStorage('token', undefined)

  const titleValue = getValue({
    field: {
      name: 'title',
      type: 'text',
      options: {
        label: 'Title',
      },
    },
  })

  useEffect(() => {
    if (name && ready) {
      if (titleValue?.default && typeof titleValue?.default === 'string') {
        setTitle(titleValue.default)
      } else if (titleValue?.en) {
        setTitle(titleValue.en)
      } else if (titleValue?.default?.en) {
        setTitle(titleValue.default?.en)
      } else {
        setTitle(
          `${mode === 'edit' ? 'Edit' : 'Create'} ${data?.modelConfig?.title}`
        )
      }
    }
  }, [mode, titleValue])

  const get = async () => {
    const body: BodyDataType = {
      modelName: modelName!,
    }

    if (urlId) {
      body._id = urlId
    }

    const { valid, data } = await fetchAPI({
      url: 'model/get',
      body,
      token: getToken(),
    })

    if (!valid) logout()

    setMode(urlId ? 'edit' : 'create')
    setName(modelName as string)

    if (modelName === 'Installation') {
      data.models[modelName] = addDataApiIdField(data.models[modelName])
    }
    setData(data)
  }

  useEffect(() => {
    get()
    setActiveTab('information')
  }, [modelName, urlId, navigate])

  const makePublicAndSave = async () => {
    const field = {
      name: 'status',
      type: 'select',
      options: {
        label: 'Status',
      },
      parentField: {
        name: 'publication',
        type: 'model',
        options: {
          label: 'Publication',
        },
      },
    }
    updateStored(field, { default: 'public' })
    save()
  }

  const handleSave = async () => {
    setSaving(true)
    if (storedData?.bestiarioApp && mode === 'create') {
      setSaving(false)
      setShowBestiarioModal(true)
    } else if (modelName === 'Installation' && mode === 'create') {
      const appInfo = await fetchAPI({
        url: 'model/get',
        body: { modelName: 'App', _id: storedData?.app },
        token: getToken(),
      })
      const groupAppId = appInfo?.data?.stored?.groupAppId

      if (groupAppId) {
        const appId = appInfo.data?.stored?.appId
        const apps: string[] = [
          groupAppId,
          ...(data?.find?.BestiarioTechnology.map(
            (tech: any) => `${groupAppId}_${tech.title}`
          ) as string[]),
        ]
        const index = apps.indexOf(appId)
        if (index > -1) {
          apps.splice(index, 1)
        }
        setSaving(false)
        setShowDuplicateInstallationModal(true)
        setGroupApps(apps)
      } else {
        save()
      }
    } else {
      save()
    }
  }

  const save = async (type = 'default') => {
    setShowPublishModal(false)

    if ((data && name && hasErrors(data.models[name])) || hasErrors()) {
      setShowErrors(true)
      setSaving(false)
      return
    }

    if (!storedData) {
      return
    }

    setShowErrors(false)
    setSaving(true)

    const body: any = { ...storedData, modelName }
    if (type === 'createBestiarioApps') {
      body.createBestiarioApps = true
      if (bestiarioCustomerId) {
        body.customerIdForInstallations = bestiarioCustomerId
      }
      if (bestiarioIncludedRules) {
        body.includedRulesForInstallations = bestiarioIncludedRules
      }
      if (bestiarioExcludedRules) {
        body.excludedRulesForInstallations = bestiarioExcludedRules
      }
    }

    if (type === 'duplicateInsallationForGroupApps') {
      body.duplicateInsallationForGroupApps = true
      body.groupApps = groupApps
    }

    const { valid, data: responseData } = await fetchAPI({
      url: 'model/update',
      method: 'PUT',
      body,
      token: getToken(),
    })

    if (!valid) logout()

    if (responseData?.error) {
      setSaving(false)
      window?.alert(responseData.error)
      return
    }

    if (typeof urlId === 'undefined') {
      navigate(`/admin/edit/${modelName}/${responseData._id}`, {
        state: { prevPath: 'edit' },
      })
    } else {
      setStoredData({ ...(responseData as StoredDataType) })
      setSaved(true)
      setTimeout(() => setSaved(false), 3000)
    }

    setShowBestiarioModal(false)
    setShowDuplicateInstallationModal(false)
    setSaving(false)
  }

  const createModelTransformer = async () => {
    if (!storedData) {
      return
    }

    if (!window.confirm(`Create a model transformer for ${storedData.title}?`))
      return

    await fetchAPI({
      url: 'model/create-model-transformer',
      method: 'POST',
      body: { modelName: storedData.title },
      token: getToken(),
    })
  }

  const getTabs = () => {
    const tabs: string[] = ['information']
    if (data?.models && name && data?.models[name as string]) {
      const fields = data?.models[name]

      const hasLiterals = fields.find(field => field.name === 'literals')
      if (hasLiterals) {
        tabs.push('literals')
      }

      const hasPublication = fields.find(field => field.name === 'publication')
      if (hasPublication) {
        if (hasPublication?.showIf) {
          let valid = false
          Object.keys(
            hasPublication?.showIf as { [key: string]: unknown }
          ).forEach(key => {
            if (
              storedData &&
              storedData[key] ===
                (hasPublication?.showIf as { [key: string]: unknown })[key]
            ) {
              valid = true
            }
          })
          if (valid) {
            tabs.push('publication')
          }
        } else {
          tabs.push('publication')
        }
      }

      const hasSeo = fields.find(field => field.name === 'seo')
      if (hasSeo) {
        tabs.push('seo')
      }

      if (urlId) {
        tabs.push('actions')
      }

      if (storedData?.modelName === 'Installation') {
        tabs.push('basicrules', 'advancedrules', 'reportedevents')
      }
    } 
    return tabs
  }

  const getFields = (tab: string) => {
    const fields = data?.models[name as string]
    if (fields) {
      if (tab === 'publication') {
        return fields.filter(field => field.name === 'publication')
      } else if (tab === 'literals') {
        return fields.filter(field => field.name === 'literals')
      } else if (tab === 'seo') {
        return fields.filter(field => field.name === 'seo')
      } else if (tab === 'advancedrules') {
        return fields.filter(field => field.options?.advancedTab)
      } else {
        return fields.filter(
          field =>
            !['publication', 'seo', 'literals'].includes(field.name) &&
            !field.options?.advancedTab
        )
      }
    }
    return []
  }

  if (!ready) {
    return (
      <div className='relative h-full pb-12'>
        <div className='pt-12 pb-8'>
          <Container>
            <span className='text-gray'>Loading...</span>
          </Container>
        </div>
      </div>
    )
  }

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (!e.target.files) return
    setFile(e.target.files[0])
  }

  const uploadEAN = async () => {
    if (!file) return
    if (file.type !== 'text/csv') {
      alert('Only CSVs allowed')
      return
    }
    setUploadingEan(true)
    setUploadingEan(true)
    try {
      const signedUrlResponse = await fetch(
        `${process.env.REACT_APP_API_URL}products/get-signed-s3-url`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            client: urlId,
          }),
        }
      )
      const { url: signedUrl } = await signedUrlResponse.json()
      const s3Response = await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      })
      const s3 = await s3Response.text()
      setImportResponse({ valid: true, message: s3 })
    } catch (err) {
      setImportResponse({ valid: false, error: err as string })
    } finally {
      setUploadingEan(false)
    }
  }

  return (
    <div className='relative h-full pb-12 bg-grayLighter'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='pb-8 bg-white'>
        <Container>
          <>
            <div className='flex items-center justify-between'>
              <div className='w-2/3 md:w-1/2'>
                <h1 className='font-medium text-xxl'>{title}</h1>
                {storedData &&
                  ready &&
                  (storedData?.publication as { [key: string]: unknown }) &&
                  ((storedData.publication as { [key: string]: unknown })
                    .status as string) && (
                    <>
                      <div className='text-xs uppercase'>
                        <span className='text-sm'>{getLiteral('status')}:</span>
                        <span className='ml-2 text-sm'>
                          {getLiteral(
                            (
                              storedData.publication as {
                                [key: string]: unknown
                              }
                            ).status as string
                          )}
                        </span>
                      </div>
                    </>
                  )}
                {storedData &&
                  ready &&
                  user?.role === 'admin' &&
                  data?.stored.locked === true && (
                    <>
                      <div className='text-xs uppercase'>
                        <span className='text-sm'>
                          {getLiteral('locked_text')}
                        </span>
                      </div>
                    </>
                  )}
              </div>
              {ready && (
                <div>
                  <div className='flex items-center'>
                    {saved && (
                      <span className='mr-4 text-xs text-gray'>
                        {getLiteral('saved_successfully')}
                      </span>
                    )}
                    <div>
                      <Button
                        theme='dark'
                        loading={saving}
                        onClick={() => {
                          if (
                            data &&
                            data?.modelConfig?.hasPublication &&
                            mode === 'create'
                          ) {
                            if (
                              !storedData ||
                              (
                                storedData?.publication as {
                                  [key: string]: string
                                }
                              ).status !== 'public'
                            ) {
                              setShowPublishModal(true)
                              return
                            }
                          }
                          handleSave()
                        }}
                      >
                        {getLiteral('save')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </Container>
      </div>
      {data?.fileInfo && (
        <div className='pb-8 bg-white'>
          <Container>
            <div className='p-4 border border-gray'>
              <div className='flex items-center justify-between'>
                <h2 className='font-medium text-md'>
                  {getLiteral('model_transformer')}{' '}
                </h2>
                <div>
                  <Button
                    theme='dark'
                    onClick={async () => {
                      if (!data?.fileInfo?.exists) {
                        await createModelTransformer()
                      }
                      window.open(data?.fileInfo?.vscodeLink)
                    }}
                  >
                    {data?.fileInfo.exists
                      ? 'Edit in VSCode'
                      : 'Create in VSCode'}
                  </Button>
                </div>
              </div>
              {!data?.fileInfo.exists && (
                <>
                  <p>{getLiteral('model_transformer_does_not_exist')}</p>
                </>
              )}
              {data?.fileInfo.exists && (
                <>
                  <pre className='p-4 mt-4 text-xs bg-grayLighter roudned'>
                    {data?.fileInfo?.contents}
                  </pre>
                </>
              )}
            </div>
          </Container>
        </div>
      )}
      <div className='flex-1 h-full'>
        {data?.errors && (
          <>
            <div className='pb-8 bg-white errors'>
              <Container>
                <>
                  {data?.errors.map(error => (
                    <div key={error} className='px-4 py-2 bg-error'>
                      {getLiteral(error)}
                    </div>
                  ))}
                </>
              </Container>
            </div>
          </>
        )}
        {data && ready && name && modelName === name && (
          <>
            <div className='bg-white border-b border-grayLight'>
              <Container>
                <div className='flex'>
                  {getTabs().map(tab => (
                    <div
                      key={tab}
                      className={`cursor-pointer hover:border-black hover:border-b mr-8 text-md pb-1 font-medium -mb-px ${
                        tab === activeTab ? 'border-b border-black' : ''
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {getLiteral(tab) as string}
                    </div>
                  ))}
                </div>
              </Container>
            </div>
            <div className='pt-12'>
              <Container>
                <>
                  {getTabs().map(tab => (
                    <div
                      key={tab}
                      className={`${tab === activeTab ? '' : 'hidden'}`}
                    >
                      {tab === 'actions' &&
                        typeof storedData?._id !== 'undefined' && (
                          <ActionsTab
                            id={storedData?._id as string}
                            modelName={modelName}
                            url={data?.url as { [key: string]: string }}
                            get={get}
                          />
                        )}

                      {tab === 'basicrules' && (
                        <BasicRulesTab
                          includedRules={storedData?.includedRules as Rule[]}
                          excludedRules={storedData?.excludedRules as Rule[]}
                          storedData={storedData as StoredDataType}
                        />
                      )}

                      {tab === 'reportedevents' && (<ReportedEventsTab />)}

                      <div
                        className={`${
                          tab === 'actions' || tab === 'basicrules' || tab === 'reportedevents'
                            ? 'hidden'
                            : ''
                        }`}
                      >
                        <Form fields={getFields(tab)} name={name} />
                      </div>
                      {modelName === 'Installation' &&
                        tab === 'advancedrules' && (
                          <div className='mt-8 text-xs'>
                            <p className='block font-light uppercase border-b select-none border-grayLight'>
                              Import EAN via CSV
                            </p>
                            <div className='p-4 mt-2 bg-white border border-grayLight'>
                              <p className='mb-4 '>
                                You must select a ; separated CSV file with 2
                                columns. The first column must be the EAN of the
                                product, and the second one the name of the
                                product. Check the casing for this two columns
                              </p>
                              <p className='ml-2 '>CSV File</p>
                              <input
                                type='file'
                                name='file'
                                onChange={handleFileChange}
                                className={`w-full px-2 py-1 bg-white outline-none placeholder-gray placeholder-opacity-50 border border-grayLight`}
                              />
                              {file && (
                                <div className='mt-2'>
                                  <Button
                                    theme='dark'
                                    onClick={() => {
                                      if (file) {
                                        uploadEAN()
                                      }
                                    }}
                                    disabled={uploadingEan}
                                  >
                                    {uploadingEan
                                      ? 'Loading...'
                                      : 'Upload EANs'}
                                  </Button>
                                </div>
                              )}
                              <div className='mt-2'>
                                <Button
                                  theme='outline-dark'
                                  onClick={() => {
                                    downloadCSV(
                                      `ean;name;image_url
                                  3365440800670;Yves Saint Laurent Fragrance For Him 100ml - Yves Saint Laurent;https://images.shopdutyfree.com/image/upload/c_pad,h_500,q_80,w_500/v1531415513/040/001/002/3365440003866/3365440003866_1_default_default.jpg`,
                                      'eans-feed-valid.csv'
                                    )
                                  }}
                                >
                                  Download example valid CSV
                                </Button>
                              </div>
                              {importResponse && importResponse.error && (
                                <div className='p-4 mt-4 border-2 border-solid border-error'>
                                  <h2>There was a problem with the import</h2>
                                  {importResponse?.error && (
                                    <div>{importResponse.error}</div>
                                  )}
                                  {importResponse.message && (
                                    <span>{importResponse.message}</span>
                                  )}
                                </div>
                              )}
                              {importResponse && importResponse.valid && (
                                <div className='mt-8 '>
                                  <p className='mb-2'>
                                    EANs Feed uploaded successfully. The CSV is
                                    being processed in the background. Please
                                    check the client in a few seconds to see if
                                    its rules have been updated.
                                  </p>
                                  <p className='border bg-grayLighter border-grayLight'>
                                    {importResponse.message}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
                </>
              </Container>
            </div>
          </>
        )}
      </div>
      {showPublishModal && (
        <div className='fixed inset-0 flex items-center justify-center w-full h-full z-90 bg-blackOpacity'>
          <div className='w-4/5 p-4 bg-white md:w-2/5'>
            <h2 className='text-xl'>{getLiteral('publication')}</h2>
            <p className='mb-2'>{getLiteral('publish_and_save_text')}</p>
            <div className='flex'>
              <div className='mr-2'>
                <Button theme='dark' onClick={makePublicAndSave}>
                  {getLiteral('publish_and_save')}
                </Button>
              </div>
              <Button theme='outline-dark' onClick={save}>
                {getLiteral('just_save')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {showBestiarioModal && (
        <ModalBestiarioApps
          data={data}
          storedData={storedData}
          setShowBestiarioModal={setShowBestiarioModal}
          setBestiarioCustomerId={setBestiarioCustomerId}
          bestiarioIncludedRules={bestiarioIncludedRules}
          setBestiarioIncludedRules={setBestiarioIncludedRules}
          bestiarioExcludedRules={bestiarioExcludedRules}
          setBestiarioExcludedRules={setBestiarioExcludedRules}
          save={save}
          saving={saving}
        />
      )}
      {showDuplicateInstallationModal && groupApps.length > 0 && (
        <ModalDuplicateInstallation
          storedData={storedData}
          setShowDuplicateInstallationModal={setShowDuplicateInstallationModal}
          save={save}
          saving={saving}
          groupApps={groupApps}
        />
      )}
    </div>
  )
}

export default Edit
