export default function downloadCSV(data: BlobPart, filename: string) {
  // Create a CSV file blob
  const csvData = new Blob([data], { type: 'text/csv' });

  // Create a download link element
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(csvData);
  downloadLink.download = filename;

  // Append the link to the document body
  document.body.appendChild(downloadLink);

  // Trigger the download
  downloadLink.click();

  // Clean up
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadLink.href);
}