export const parseEventData = (item: any) => {
  if (!item) return null;

  const parsedData = item
    .slice(1, -1) // Remove the outer brackets
    .split('}, {') // Split into each object representation
    .map((item: any) => {
      const [propertyPart, valuePart] = item.replace(/[{}]/g, '').split(', ');
      const property = propertyPart.split('=')[1];
      const value = valuePart
        .split('=')[1]
        .replace(/[\[\]]/g, '')
        .split(', ');
      return { property, value };
    });

  return parsedData.map((item: any, index: number) => (
    <div key={index}>
      <strong>{item.property}:</strong>{' '}
      <span>
        {Array.isArray(item.value) ? item.value.join(', ') : item.value}
      </span>
    </div>
  ));
};
