import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom";
import Button from "../components/Button";
import Container from "../components/Container";
import { GlobalContext } from "../contexts/GlobalContext";
import useLocalStorage from "../hooks/useLocalStorage";
import fetchAPI from "../lib/fetchAPI";
import ModalPendingEvent from "../components/ModalPendingEvent";
import eventIsReady from "../lib/eventIsReady";

const ListPendingEvents = () => {
  const { name: modelName } = useParams();
  const { appConfig, getToken, logout } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useLocalStorage(`${modelName}-sorting`, null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [title] = useState('Pending events');
  const [page, setPage] = useState(searchParams?.get('page') ? Number(searchParams.get('page')) : 0);
  const [listItem, setListItem] = useState()
  const [refresh, setRefresh] = useState(false);

  const getData = async () => {
    setLoading(true);

    const body = {
      modelName,
      page,
    };

    body.itemsPerPage = appConfig?.app?.itemsPerPage || 50;

    if (sorting) {
      body.sorting = sorting;
    }

    const { valid, data } = await fetchAPI({
      url: "events/get-pending-events",
      body,
      token: getToken(),
    });

    if (!valid) logout();

    if (data && data.error) {
      console.error(data.error);
      setLoading(false);
      setData({
        events: []
      });
      return;
    }

    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelName, sorting, page]);

  useEffect(() => {
    setSearchParams({
      page,
    });
  }, [page, setSearchParams]);

  useEffect(() => {
    if (refresh) getData()
    setRefresh(false)
  }, [refresh])

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Container>
          <div className="flex items-center justify-between mb-8">
            <h1 className="font-medium text-xxl">{title}</h1>
          </div>
          {loading && <div className="text-gray">Loading...</div>}
          {!loading && data && data.events && data.events.length === 0 && (
            <div>Nothing to list</div>
          )}
          {!loading && data && data.events.length > 0 && (
            <table className="w-full mb-8">
              <thead className="w-full text-left bg-white border-b border-grayLight">
                <tr>
                  <th className="relative p-2 text-xs font-normal">App</th>
                  <th className="relative p-2 text-xs font-normal">Installation</th>
                  <th className="relative p-2 text-xs font-normal">Data API ID (appId.installationId)</th>
                  <th className="relative p-2 text-xs font-normal">Pending events</th>
                  <th align="right" className="py-2 text-xs font-normal uppercase"></th>
                </tr>
              </thead>
              <tbody>
                {data?.events?.map((item, index) => (
                  <tr
                    key={item._id}
                    className="relative bg-white border-b border-grayLight hover:bg-grayLighter"
                  >
                    <td className="p-2 py-3 text-sm">{item.app}</td>
                    <td className="p-2 py-3 text-sm">{item.installation || 'default'}</td>
                    <td className="p-2 py-3 text-sm">{`${item.app}.${item.installation || 'default'}`}</td>
                    <td className="p-2 py-3 text-sm">{item.count}</td>
                    <td align="right" className="p-2 py-3 text-right">
                      {eventIsReady(data?.installations, item) ?
                        <span className="text-green-500">Ready to process</span>
                      :
                        <Button theme={"outline-dark"} onClick={() => setListItem(item)}>Fix</Button>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!loading && data.numberOfPages > 1 && data.items.length > 0 && (
            <div className="flex justify-end my-8 text-sm text-gray">
              <div className="flex">
                {page - 3 > 0 && <span className="mr-2">...</span>}
                {Array.from({ length: data.numberOfPages }).map((_, index) =>
                  Math.abs(index - page) < 5 ? (
                    <span
                      key={index}
                      onClick={() => setPage(index)}
                      className={`mr-2 cursor-pointer ${
                        index === page ? "text-black" : ""
                      }`}
                    >
                      {index + 1}
                    </span>
                  ) : null
                )}
                {page + 3 < data.numberOfPages && <span>...</span>}
              </div>
            </div>
          )}
          { listItem &&
            <ModalPendingEvent
              apps={data?.apps}
              installations={data?.installations}
              clients={data?.clients}
              item={listItem}
              setItem={setListItem}
              refresh={setRefresh}
              tablesList={data?.tablesList}
            />
          }
        </Container>
      </div>
    </div>
  );
};

export default ListPendingEvents;
