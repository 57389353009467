import { PttFieldType } from '../../types'
import Field from './Field'

interface SectionProps extends PttFieldType {}

const Section = ({ field }: SectionProps) => {
  return (
    <div className='mt-8'>
      <Field field={field} />
    </div>
  )
}

export default Section
