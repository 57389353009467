import Modal from "./Modal";
import Button from "./Button";

const ModalDuplicateInstallation = ({
  storedData,
  setShowDuplicateInstallationModal,
  save,
  saving,
  groupApps,
}: {
  storedData: any;
  setShowDuplicateInstallationModal: any;
  save: any;
  saving: boolean;
  groupApps: string[];
}) => (
  <Modal>
    <div>
      <h3 className="mb-2 text-xl">Create installations for Bestiario apps</h3>
      <p>
        Do you want to create duplicates of &nbsp;
        <span className="p-1 font-mono text-xs bg-grayLight ">{`${storedData?.title}`}</span>{' '}
        installation for all apps of the same Bestiario group:
      </p>
      <ul className="mt-2 ml-4">
        {groupApps.map((app: string) => (
          <li key={app} className="list-disc list-inside">{app}</li>
        ))}
      </ul>
      <div className="flex justify-between mt-6">
        <Button
          theme="outline-dark"
          onClick={() => {
            setShowDuplicateInstallationModal(false)
            save()
          }}
          disabled={saving}
        >
          Continue without duplicating
        </Button>
        <Button
          theme="dark"
          onClick={() => save('duplicateInsallationForGroupApps')}
          disabled={saving}
          loading={saving}
        >
          Duplicate for all apps
        </Button>
      </div>
    </div>
  </Modal>
)

export default ModalDuplicateInstallation;