export const addDataApiIdField = (fields: any) => {
  const titlePosition = fields.findIndex((field: any) => field.name === 'title')
  if (titlePosition !== -1 && fields[titlePosition+1].name !== 'dataApiId') {
    const dataApiId = {
      "name": "dataApiId",
      "type": "text",
      "options": {
          "label": "Data API ID",
          "info": "appId.installationId",
          "disabled": true
      },
      "readonly": true,
    }
    fields.splice(titlePosition+1, 0, dataApiId)
  }
  return fields
}