import generateToken from '../lib/generateToken'
import { StoredDataType } from '../types'
import BasicRules from './BasicRules'
import BasicRulesIngredientsProportions from './BasicRulesIngredientsProportions'

export type Rule = {
  key: string
  type: string
  filter: string
  table: string
  column: string
  row: string
}

type BasicRulesProps = {
  includedRules: Rule[]
  excludedRules: Rule[]
  storedData: StoredDataType
}

const defaultRules: { [key: string]: Rule } = {
  brandIncluded: {
    key: generateToken(30),
    type: 'included',
    filter: 'row',
    table: 'brands',
    column: 'id',
    row: '',
  },
  brandExcluded: {
    key: generateToken(30),
    type: 'exclude',
    filter: 'row',
    table: 'brands',
    column: 'id',
    row: '',
  },
  perfumeIncluded: {
    key: generateToken(30),
    type: 'included',
    filter: 'row',
    table: 'perfumes',
    column: 'id',
    row: '',
  },
  perfumeExcluded: {
    key: generateToken(30),
    type: 'exclude',
    filter: 'row',
    table: 'perfumes',
    column: 'id',
    row: '',
  },
  homeFragranceIncluded: {
    key: generateToken(30),
    type: 'included',
    filter: 'row',
    table: 'home_fragrances',
    column: 'id',
    row: '',
  },
  homeFragranceExcluded: {
    key: generateToken(30),
    type: 'exclude',
    filter: 'row',
    table: 'home_fragrances',
    column: 'id',
    row: '',
  },
  ingredientProportion: {
    key: generateToken(30),
    type: 'exclude',
    filter: 'column',
    table: 'perfumes_ingredients',
    column: 'proportion',
    row: '',
  },
}

function findRule(
  rules: Rule[],
  type: string,
  table: string,
  defaultRule: Rule
): Rule {
  const foundRule = rules
    ? rules.find(r => r.type === type && r.table === table)
    : defaultRule
  return foundRule || defaultRule
}

function BasicRulesTab({
  includedRules,
  excludedRules,
  storedData,
}: BasicRulesProps) {
  if (!storedData) {
    return <div>Loading...</div>
  }

  return (
    <div className='min-h-[70vh] flex flex-col gap-2 text-xs'>
      <BasicRules
        title='Brand'
        includedRule={findRule(
          includedRules,
          'included',
          'brands',
          defaultRules.brandIncluded
        )}
        excludedRule={findRule(
          excludedRules,
          'exclude',
          'brands',
          defaultRules.brandExcluded
        )}
        storedData={storedData}
      />
      <BasicRules
        title='Perfume'
        includedRule={findRule(
          includedRules,
          'included',
          'perfumes',
          defaultRules.perfumeIncluded
        )}
        excludedRule={findRule(
          excludedRules,
          'exclude',
          'perfumes',
          defaultRules.perfumeExcluded
        )}
        storedData={storedData}
      />
      <BasicRules
        title='Home Fragrance'
        includedRule={findRule(
          includedRules,
          'included',
          'home_fragrances',
          defaultRules.homeFragranceIncluded
        )}
        excludedRule={findRule(
          excludedRules,
          'exclude',
          'home_fragrances',
          defaultRules.homeFragranceExcluded
        )}
        storedData={storedData}
      />
      <BasicRulesIngredientsProportions
        title='Ingredients'
        data={storedData}
        excludedRule={findRule(
          excludedRules,
          'exclude',
          'perfumes_ingredients',
          defaultRules.ingredientProportion
        )}
      />
    </div>
  )
}

export default BasicRulesTab
