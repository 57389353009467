import { useContext, useEffect, useState } from 'react';
import Button from './Button';
import fetchAPI from '../lib/fetchAPI';
import { useParams } from 'react-router';
import { GlobalContext } from '../contexts/GlobalContext';
import { parseEventData } from '../lib/parseEventData';

function ReportedEventsTab() {
  const { id } = useParams();
  const { getToken, logout } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [lastRefresh, setLastRefresh] = useState<Date | null>(null);
  const [queryId, setQueryId] = useState<string | null>(null);

  const itemsPerPage = 10;
  const numberOfPages = Math.ceil(data?.events?.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const getData = async (queryExecutionId?: string) => {
    const body: {
      installationId: string | undefined;
      queryExecutionId?: string;
    } = { installationId: id };

    if (queryExecutionId) {
      body.queryExecutionId = queryExecutionId;
    }

    const { valid, data } = await fetchAPI({
      url: 'events/get-installation-events',
      body,
      token: getToken(),
    });

    if (!valid) logout();

    const { queryStatus, events } = data;

    if (queryExecutionId && queryStatus !== 'SUCCEEDED') {
      return false;
    }

    setData({ events });

    if (events?.length === 0) {
      setLastRefresh(new Date());
      return true;
    }
    setLastRefresh(events?.[0]?.fetchedAt || null);
    return true;
  };

  const requestData = async () => {
    setLoading(true);
    const body = { installationId: id, queryExecutionId: queryId };
    const { valid, data } = await fetchAPI({
      url: 'events/request-installation-events',
      body,
      token: getToken(),
    });

    if (!valid) logout();

    const { queryExecutionId, events } = data;

    // If cached events with less than 5 minutes, show them
    if (events) {
      setData({ events });
      setLastRefresh(events[0]?.fetchedAt);
      setLoading(false);
    }

    // Else, query them
    if (queryExecutionId) {
      setQueryId(queryExecutionId);
      // Check the status of the query every 5 seconds until it's successful
      const intervalId = setInterval(async () => {
        const success = await getData(queryExecutionId);
        if (success) {
          clearInterval(intervalId);
          setLoading(false);
        }
      }, 5000);
    }
  };

  // On load, get the cached data if it exists
  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      {/* // Header info and button */}
      <div className="flex justify-between items-center">
        <p className="text-xs font-light uppercase">
          Last request:{' '}
          {lastRefresh ? new Date(lastRefresh).toLocaleString() : 'None'}
        </p>
        <div className="flex gap-2 items-center">
          {data &&
            lastRefresh &&
            new Date().getTime() - new Date(lastRefresh).getTime() < 300000 && (
              <p className="text-xs uppercase">
                You can load the latest events after 5 minutes
              </p>
            )}
          <Button
            theme="dark"
            disabled={
              loading ||
              (lastRefresh &&
                new Date().getTime() - new Date(lastRefresh).getTime() <
                  300000) ||
              false
            }
            loading={loading}
            onClick={() => setData(requestData)}
          >
            Load events
          </Button>
        </div>
      </div>
      {/* // Paginated list */}
      {data && data?.events?.length > 0 && (
        <>
          <table className="w-full text-xs">
            <thead className="w-full text-left border-b border-black">
              <tr>
                <th className="relative p-2 font-bold">User ID</th>
                <th className="relative p-2 font-bold">Origin</th>
                <th className="relative p-2 font-bold">Environment</th>
                <th className="relative p-2 font-bold">Date</th>
                <th className="relative p-2 font-bold">Type</th>
                <th className="relative p-2 font-bold">Event data</th>
              </tr>
            </thead>
            <tbody>
              {data?.events
                ?.slice(startIndex, endIndex)
                .map((item: any, index: number) => (
                  <tr
                    key={index}
                    className="relative border-b border-black hover:bg-grayLight/50"
                  >
                    <td className="p-2 py-3 text-sm max-w-[240px] overflow-hidden">
                      {item.userid}
                    </td>
                    <td className="p-2 py-3 text-sm">{item.ip}</td>
                    <td className="p-2 py-3 text-sm">{item.environment}</td>
                    <td className="p-2 py-3 text-sm">{item.createdat}</td>
                    <td className="p-2 py-3 text-sm">{item.name}</td>
                    <td className="p-2 py-1 text-sm">
                      {parseEventData(item.data)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* // Pagination */}
          <div className="flex justify-between">
            <Button
              theme="outline-dark"
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              Previous page
            </Button>
            <Button
              theme="outline-dark"
              disabled={page === numberOfPages}
              onClick={() => setPage(page + 1)}
            >
              Next page
            </Button>
          </div>
          <div className="flex justify-between">
            <p className="text-xs font-light uppercase">
              Number of events: {data.events.length}
            </p>
            <div className="flex text-xs text-gray">
              {page > 2 && (
                <span
                  onClick={() => setPage(1)}
                  className="mr-1 cursor-pointer"
                >
                  1{page > 3 && <span>...</span>}
                </span>
              )}
              {Array.from({ length: numberOfPages }).map((_, index) =>
                Math.abs(index + 1 - page) < 2 ? (
                  <span
                    key={index}
                    onClick={() => setPage(index + 1)}
                    className={`mx-1 cursor-pointer ${
                      index + 1 === page ? 'text-black' : ''
                    }`}
                  >
                    {index + 1}
                  </span>
                ) : null
              )}
              {numberOfPages > 4 && page < numberOfPages - 1 && (
                <span
                  onClick={() => setPage(numberOfPages)}
                  className="ml-1 cursor-pointer"
                >
                  {page < numberOfPages - 2 && <span>...</span>}
                  {numberOfPages}
                </span>
              )}
            </div>
          </div>
        </>
      )}
      {/* // Loading and messages */}
      {loading && (
        <img
          className="h-12 w-12 mx-auto"
          src="/assets/images/spinner.gif"
          alt="..."
        />
      )}
      {!data && !lastRefresh && (
        <p className="uppercase p-2 text-center">
          Click the 'LOAD EVENTS' button to retrieve the latest events
        </p>
      )}
      {data?.events?.length === 0 && lastRefresh && (
        <p className="uppercase p-2 text-center">No recent events available</p>
      )}
    </div>
  );
}

export default ReportedEventsTab;
