import React, { useContext, useEffect } from 'react'
import { EditDataContext } from '../../contexts/EditDataContext'
import { PttFieldType } from '../../types'
import Field from './Field'

interface CheckboxProps extends PttFieldType {}

const Checkbox = ({
  field,
}: CheckboxProps) => {

  const { updateStored, getValue, getErrors, getLanguage, showErrors, storedData } = useContext(EditDataContext)
  
  const language = getLanguage({ field })
  const value = getValue({ field, defaultValue: field.options.defaultValue === true || false })
  const errors = getErrors({ field })

  useEffect(() => {
    if (storedData && typeof storedData[field.name] === 'undefined') {
      value[language] = field.options.defaultValue === true || false
      updateStored(field, value);
    }
  }, [])

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxValue = e.target.checked
    value[language] = checkboxValue ? true : false
    updateStored(field, value)
  }

  return (
    <Field
      field={field}
    >
      <input
        name={field.name}
        className={`ml-2 mt-2 rounded px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } bg-white outline-none placeholder-gray placeholder-opacity-50`}
        type="checkbox"
        checked={!!Number(value[language])}
        onChange={onChangeText}
        value="1"
        id={field.name}
      />
    </Field>
  )
}

export default Checkbox
