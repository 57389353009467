import Modal from './Modal'
import ModalRules from './ModalRules'
import Button from './Button'

const ModalBestiarioApps = ({
  data,
  storedData,
  setShowBestiarioModal,
  setBestiarioCustomerId,
  bestiarioIncludedRules,
  setBestiarioIncludedRules,
  bestiarioExcludedRules,
  setBestiarioExcludedRules,
  save,
  saving,
}: {
  data: any
  storedData: any
  setShowBestiarioModal: any
  setBestiarioCustomerId: any
  bestiarioIncludedRules: any
  setBestiarioIncludedRules: any
  bestiarioExcludedRules: any
  setBestiarioExcludedRules: any
  save: any
  saving: boolean
}) => (
  <Modal size={"w-4/5 md:w-3/5"}>
    <div className=''>
      <h3 className="mb-2 text-xl">Create Bestiario apps</h3>
      <p>
        Do you want to create duplicates of &nbsp;
        <span className="p-1 font-mono text-xs bg-grayLight ">{`${storedData?.title}`}</span>{' '}
        app with all Bestiario technologies?
      </p>
      <ul className="mt-2 ml-4">
        {data?.find?.BestiarioTechnology &&
          data?.find?.BestiarioTechnology.map((tech: any) => (
            <li
              key={tech._id}
              className="text-xs list-disc list-inside"
            >{`${storedData?.title}_${tech.title}`}</li>
          ))}
      </ul>
      <div className='py-2 my-2 text-xs border-t border-b border-grayLight'>
        <p>Optional: you can assign some values to default installations that will be created for each app.</p>
        <div className="my-2">
          <label className="block text-xs text-gray">CustomerId</label>
          <input
            type="text"
            placeholder="Customer ID"
            className="px-2 py-1 placeholder-opacity-50 bg-white border outline-none placeholder-gray border-grayLight"
            onChange={(e) => setBestiarioCustomerId(e.target.value)}
          />
        </div>
        <ModalRules field={{
          name: "includedRules",
          title: "Included rules",
          value: bestiarioIncludedRules,
          setValue: setBestiarioIncludedRules,
        }} />
        <ModalRules field={{
          name: "excludedRules",
          title: "Excluded rules",
          value: bestiarioExcludedRules,
          setValue: setBestiarioExcludedRules,
        }} />
      </div>
      <div className="flex justify-between mt-6">
        <Button
          theme="danger"
          onClick={() => {
            setShowBestiarioModal(false)
            save()
          }}
          disabled={saving}
        >
          Continue without creating
        </Button>
        <Button
          theme="dark"
          onClick={() => save('createBestiarioApps')}
          disabled={saving}
          loading={saving}
        >
          Create
        </Button>
      </div>
    </div>
  </Modal>
)

export default ModalBestiarioApps
