import { arrayMoveImmutable } from "array-move";
import { useContext, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { EditDataContext } from "../../contexts/EditDataContext";
import generateToken from "../../lib/generateToken";
import { PttModelField, PttModelType } from "../../types";
import ModelsRow from "./ModelsRow";
import ModuleSelector from "./models/ModuleSelector";
import { GlobalContext } from "../../contexts/GlobalContext";

interface ModelsFieldType extends PttModelField {
  options: {
    label: string;
    decimals: number;
    models: { [key: string]: string };
  };
}

const Models = ({ field }: { field: ModelsFieldType }) => {
  const { updateStored, getValue, getLanguage, data } =
    useContext(EditDataContext);
  const { getLiteral } = useContext(GlobalContext);

  const value = getValue({ field, defaultValue: [] });
  const language = getLanguage({ field });

  const [sorting, setSorting] = useState(false);

  const [showAdd, setShowAdd] = useState(false);

  const deleteModel = (index: number) => {
    if (window.confirm("Delete item?")) {
      (value[language] as PttModelType[]).splice(index, 1);
      updateStored(field, value);
    }
  };

  const addModel = (modelName: string) => {
    const currentValue = value[language] as PttModelType[];

    let position = (currentValue.length + 1) * 10;
    if (
      currentValue.length > 0 &&
      typeof currentValue[currentValue.length - 1].position !== "undefined" &&
      currentValue[currentValue.length - 1].position > 0
    ) {
      position = currentValue[currentValue.length - 1].position + 10;
    }
    currentValue.push({
      modelName,
      _id: generateToken(30),
      position,
    });
    value[language] = currentValue;
    updateStored(field, value);
    setShowAdd(false);
  };

  const moveModel = (dragIndex: number, hoverIndex: number) => {
    const sortedValue = arrayMoveImmutable(
      value.default as PttModelType[],
      dragIndex,
      hoverIndex
    );
    sortedValue.map((item, index) => {
      item.position = (index + 1) * 10;
      return item;
    });
    value.default = sortedValue;
    updateStored(field, value);
  };

  return (
    <div>
      <div className="flex items-center justify-between pb-1 mt-1 text-xs font-light border-b border-grayLight">
        <div>
          <span className="uppercase">{field?.options?.label}</span>
        </div>
        {(field?.parentField !== undefined ||
          data?.stored?.locked !== true) && (
          <span className="cursor-pointer" onClick={() => setSorting(!sorting)}>
            {sorting ? getLiteral("exit_sort_mode") : getLiteral("sort_mode")}
          </span>
        )}
      </div>
      <DndProvider backend={HTML5Backend}>
        {value[language] !== "undefined" &&
          Array.isArray(value[language]) &&
          (value[language] as PttModelType[]).map(
            (v: PttModelType, index: number) => (
              <ModelsRow
                key={v._id}
                moveModel={moveModel}
                sorting={sorting}
                index={index}
                item={v}
                modelsAvailable={field?.options?.models}
                deleteModel={deleteModel}
                parentField={field}
                deletable={
                  field?.parentField !== undefined ||
                  data?.stored?.locked !== true
                }
              />
            )
          )}
      </DndProvider>
      {(field?.parentField !== undefined || data?.stored?.locked !== true) && (
        <div className="mt-2">
          <span
            onClick={() => setShowAdd(!showAdd)}
            className="px-2 py-1 text-xs text-white border border-black rounded cursor-pointer bg-primary hover:bg-transparent hover:text-black"
          >
            {showAdd ? getLiteral("cancel") : getLiteral("add_module")}
          </span>
          {showAdd && (
            <ModuleSelector
              models={field?.options?.models}
              addModelCallback={addModel}
              cancelCallback={() => setShowAdd(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Models;
