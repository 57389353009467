import React, { useContext, useState } from 'react'
import { EditDataContext } from '../../contexts/EditDataContext'
import { PttFieldType } from '../../types'
import Field from './Field'
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Button from '../../components/Button'
import { GlobalContext } from '../../contexts/GlobalContext'

interface RelatedAppsInstallationsProps extends PttFieldType {}

const RelatedAppsInstallations = ({ field }: RelatedAppsInstallationsProps) => {
  const { getLiteral, getToken, logout, getUser } = useContext(GlobalContext);
  const { data } = useContext(EditDataContext);
  const navigate = useNavigate();


  const InstallationCard = ({ item }: any): React.ReactElement => {
    const [showApiKey, setShowApiKey] = useState(false)
    const copyToClipboard = (text: string) => {
      const textField = document.createElement('textarea')
      textField.innerText = text
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
    }
    return (
      <div className="flex items-start justify-between px-2 py-2 mb-1 ">
        <div className="flex flex-wrap w-full">
          <div className="w-1/2">
            <p className="text-xs font-light uppercase ">Name</p>
            <Link
              className="mr-4 cursor-pointer hover:text-primary"
              to={{ pathname: `/admin/edit/Installation/${item._id}?page=0` }}
              >{item.title}</Link>
          </div>
          <div className="w-1/2">
            <p className="text-xs font-light uppercase ">Installation ID</p>
            <p>{item.installationId || '-'}</p>
          </div>
          {item.hasApiKey === false ? <></> :
            <div className="w-1/2 pr-4 mt-2">
              <div className="flex justify-between text-xs font-light">
                <p className="uppercase ">Api Key
                </p>
                <span className="text-right normal-case ">
                  <span className="mx-1 cursor-pointer hover:text-primary hover:wght-bold" onClick={() =>
                    setShowApiKey(!showApiKey)
                  }>Show</span>
                  <span>|</span>
                  <span className="ml-1 cursor-pointer hover:text-primary hover:wght-bold" onClick={() => {
                    copyToClipboard(item.apikey)
                  }}>Copy</span>
                </span>
              </div>
              <input
                name="apiKey"
                className={`w-full px-2 py- bg-white outline-none placeholder-gray placeholder-opacity-50 border border-grayLight`}
                type={(showApiKey ? 'text' : 'password')}
                value={item.apikey}
                disabled
              />
            </div>
          }
          <div className="w-1/2 mt-2">
            <p className="text-xs font-light uppercase ">Country</p>
            <p>{item.country || '-'}</p>
          </div>
        </div>
        <Button
          theme="outline-dark"
          onClick={() => navigate(`/admin/edit/Installation/${item._id}?page=0`)}
        >{getLiteral("edit")}</Button>
      </div>
    );
  };

  const AppCard = ({ item, key }: any): React.ReactElement => {
    return (
      <div key={item._id} className="relative py-2 mb-2 bg-white border border-grayLight ">
        <div className="flex items-start justify-between px-4 py-2">
          <div className="flex w-full">
            <div className="w-1/2">
              <p className="text-xs font-light uppercase ">Name</p>
              <Link
                className="mr-4 cursor-pointer hover:text-primary"
                to={{ pathname: `/admin/edit/App/${item._id}?page=0` }}
              >{item.title}</Link>
            </div>
            <div className="w-1/2">
              <p className="text-xs font-light uppercase ">Category</p>
              <p>{item.category || '-'}</p>
            </div>
          </div>
          <Button
            theme="dark"
            onClick={() => navigate(`/admin/edit/App/${item._id}?page=0`)}
          >{getLiteral("edit")}</Button>
        </div>
        <div className="w-full px-4 py-0">
          <div className="flex flex-col">
            <div className="text-xs font-light uppercase ">
              {data?.modelConfig?.title === 'Client' ? 'Installations' : ''}
            </div>
            {item?.installation && item?.installation.length > 0 && item?.installation.map((subitem : any) => <div className="mb-1 bg-grayLighter"><InstallationCard item={subitem} /></div>)}
            {data?.modelConfig?.title === 'Client' &&
              <div className="my-2 text-xs">
                <Button 
                  theme="outline-dark"
                  onClick={() =>
                    navigate(
                      `/admin/edit/Installation?app=${item._id}`
                    )
                  }
                >Create new Installation</Button>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='mt-0'>
      <Field field={field} >
        {data?.findRelated && Object.keys(data?.findRelated).map((key) =>
        <div className="mt-4 text-base">
          <h2 className="mb-2 text-xs font-light uppercase">{key.toUpperCase()}S</h2>
          {data?.findRelated && data?.findRelated[key].length === 0 && <p className="text-xs font-light">No {key}s for this {data?.modelConfig?.title}</p>}
          {data?.findRelated &&
            data?.findRelated[key].map((item) => {
              return data?.modelConfig?.title === 'Client' ?
                <AppCard item={item} key={key} />
                :
                <div className="mb-1 bg-white border border-grayLight"><InstallationCard item={item} /></div>
            })}
          <div className="mt-2">
            <Button 
              theme="dark"
              onClick={() =>
                navigate(
                  `/admin/edit/${key}?${
                    data?.modelConfig?.relatedModels &&
                    data.modelConfig.relatedModels.find(
                      (model) => model.model === key
                    )?.relatedKey
                  }=${data?.stored?._id}`
                )
              }
            >Create new {key}</Button>
          </div>
        </div>)}
      </Field>
    </div>
  )
}

export default RelatedAppsInstallations
