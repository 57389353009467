import React, { useContext, useState, useEffect } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { PttFieldType } from "../../types";
import Field from "./Field";
import { GlobalContext } from "../../contexts/GlobalContext";
import generateToken from "../../lib/generateToken";

interface ApiKeyProps extends PttFieldType {}

const ApiKey = ({ field }: ApiKeyProps): React.ReactElement => {
  const [showKey, setShowKey] = useState(false)
  const { updateStored, getValue, getErrors, getLanguage, showErrors, storedData } =
    useContext(EditDataContext);
  const { getLiteral } = useContext(GlobalContext);

  const language = getLanguage({ field });
  const value = getValue({ field });
  const errors = getErrors({ field });

  const createApiKey = () => {
    const data = generateToken(20)
    return btoa(data)
  }

  useEffect(() => {
    if (!value[language]) {
      value[language] = createApiKey()
      updateStored(field, value);
    }
  }, [])

  if (!storedData?.hasApiKey) return <></>

  return (
    <Field field={field}>
      <div className="absolute top-0 right-0 text-xs font-light select-none text-gray ">
        <span className="mx-2 cursor-pointer hover:text-primary hover:wght-bold" onClick={() => setShowKey(!showKey)}>Show</span>
        <span>|</span>
        <span className="ml-2 cursor-pointer hover:text-primary hover:wght-bold" onClick={() => {navigator.clipboard.writeText(value[language])}}>Copy</span>
      </div>
      <input
        name={field.name}
        className={`w-full px-2 py-1 bg-white outline-none placeholder-gray placeholder-opacity-50 border ${
          showErrors && errors.length > 0 ? "border-error" : "border-grayLight"
        }`}
        type={(showKey ? 'text' : 'password')}
        placeholder={
          getLiteral(
            (field?.options as { [key: string]: unknown }).label as string,
            "manager_field_"
          ) || ""
        }
        value={`${value && value[language] ? value[language] : ""}`}
        disabled
      />
    </Field>
  );
};

export default ApiKey;
